import {
	type Link,
	type PortfolioListItem,
} from '../../../types/graphql.page.ts'
import ArrowLink from '../arrowLink.tsx'
import Slider from '../slider.tsx'
import WaveTexture from '../waveTexture.tsx'

export default function PartnersBlock({
	link,
	cards,
	text,
}: {
	cards: PortfolioListItem[]
	text: string
	link: Link
}) {
	const cardsWithImages = cards.filter((card) => card.image[0])
	return (
		<article id="our-partners" className="relative py-10 md:py-[120px]">
			<WaveTexture side="right" />
			<div className="container relative z-10 flex h-full flex-col space-y-[60px] pb-14">
				<div className="flex flex-col space-y-5">
					<h2 className="header">Our Partners</h2>
					<div
						className="text-content-partners flex max-w-2xl flex-col space-y-5"
						dangerouslySetInnerHTML={{
							__html: text,
						}}
					></div>
					<ArrowLink
						className="pt-[15px]"
						url={link.url}
						type={link.type}
						text={link.text}
					/>
				</div>
			</div>
			{/* {cardsWithImages.map((card) => (
				<div key={card.uid}>{card.image[0]?.url}</div>
			))} */}
			<Slider cards={cardsWithImages} />
		</article>
	)
}
