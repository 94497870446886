// import Image from "#app/components/image.tsx";
// import { type loader as RootLoaderType } from "#app/root.tsx";
import { useParentData } from '#app/utils/misc'
// import  { type NewsListItem } from '../../../types/graphql.form.ts'
import ArrowLink from '../arrowLink.tsx'
import BackgroundImage from '../backgroundImage.tsx'
// import WaveTexture from "../waveTexture.tsx";

export default function FeaturedNewsBlock({ news }: { news: any }) {
	const {
		requestInfo: { origin },
	} = useParentData('/') as { requestInfo: { origin: string } }

	// TODO: replace with the ImageOverlayText component
	return (
		<article
			id="trusted-partners"
			className="relative bg-green-300/5 pb-16 pt-10 md:pb-[80px] md:pt-[120px]"
		>
			<div className="container relative z-10 flex h-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
				<div className="flex flex-row items-center md:w-2/5">
					<div className="flex flex-col space-y-[30px] pr-1">
						<h2 className="header">Featured News</h2>
						<div className="text-content-trusted flex flex-col space-y-5 sm:px-0">
							<h2>{news.title}</h2>
						</div>
						<ArrowLink
							className="pl-6 pt-[10px]"
							url={news.uri}
							type={'entry'}
							text="Read the full article"
						/>
					</div>
				</div>
				<div className="relative flex h-full flex-row items-center md:w-3/5">
					<div className="gray-box-bl relative mr-6 flex w-full flex-col md:mr-0 md:flex-row-reverse">
						{news.newsListingImage?.at(0)?.url ? (
							<BackgroundImage
								className="h-[250px] w-full bg-cover md:h-[467px] md:w-[688px]"
								img={news.newsListingImage[0]}
							/>
						) : (
							<BackgroundImage
								className="h-[250px] w-full bg-cover bg-center md:h-[467px] md:w-[688px]"
								frontend
								img={{
									alt: 'News',
									url: `${origin}/assets/news-placeholder.png`,
									height: 467,
									width: 467,
									title: 'News',
									uid: 'news-placeholder',
								}}
							/>
							// <img
							//   className=""
							//   src="/assets/news-placeholder.png"
							//   alt="News"
							//   title="News"
							// />
						)}
					</div>
				</div>
			</div>
		</article>
	)
}
