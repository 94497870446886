import TextImageOverlayBlock from '#app/components/blocks/textImageOverlayBlock.tsx'
// import  { type Image as ImageType, type Link } from '../../../types/graphql.form.ts'

export default function InvestmentCriteriaBlock({
	link,
	img,
	text,
}: {
	img: any[]
	text: string
	link: any
}) {
	// TODO: replace with the ImageOverlayText component
	return (
		<div className="md:mb-10">
			<TextImageOverlayBlock
				block={{
					__typename: 'contentBlocks_textImageOverlay_BlockType',
					format: [
						{ imagePosition: 'left', styles: ['wave-texture', 'accent-box'] },
					],
					image: img,
					text: text,
					link: link,
					sortOrder: 0,
					subheader: 'Investment Criteria',
					title: '',
					uid: '',
				}}
			/>
		</div>
	)
}
