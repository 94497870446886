export function getVideoId(url: string) {
	if (!url) return ''
	const vimeoRegex =
		/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i

	const youtubeRegex =
		/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/i

	if (url.includes('youtube')) {
		return url.match(youtubeRegex)?.[7] || ''
	}
	return url.match(vimeoRegex)?.[1] || ''
}
