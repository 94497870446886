import type Vimeo from '@u-wave/react-vimeo'
import * as React from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
// import VimeoBackgroundPlayer from '#app/components/vimeoBackgroundPlayer.tsx'
import { getVideoId } from '#app/utils/helpers.ts'
import Navigation from './navigation.tsx'

export default function MainHero({
	subtitle,
	videoUrl,
	rotatingText,
}: {
	subtitle: string
	videoUrl: string
	rotatingText: Array<{ uid: string; sortOrder: number; text: string }>
}) {
	const ref = React.useRef<Vimeo>(null)
	const textRef = React.useRef<HTMLHeadingElement>(null)
	const [time, setTime] = React.useState(0)

	let videoId = getVideoId(videoUrl)

	if (rotatingText?.length) {
		rotatingText = rotatingText.sort((a, b) => a.sortOrder - b.sortOrder)
	}

	React.useEffect(() => {
		const interval = setInterval(async () => {
			// @ts-ignore
			const elapsed_sec = await ref.current?.player.getCurrentTime()
			// @ts-ignore
			const duration = await ref?.current?.player.getDuration()
			const elapsed_ms = Math.floor(elapsed_sec * 1000)
			const ms = elapsed_ms % 1000
			const min = Math.floor(elapsed_ms / 60000)
			const seconds = Math.floor((elapsed_ms - min * 60000) / 1000)
			const thirdSeconds = Math.floor(seconds / 3)
			const hundreds = Number(String(ms).split('').reverse()[2]) || 0
			if (thirdSeconds === time && (seconds + 1) % 3 === 0 && hundreds === 8) {
				setTime(thirdSeconds + 1)
			}
			if (duration * 1000 - 200 <= elapsed_ms) {
				setTime(0)
			}
		}, 100)
		return () => clearInterval(interval)
	}, [time])

	return (
		<div
			id="header"
			className="z-0 h-[375px] sm:h-[500px] md:h-[675px] lg:h-[900px] xl:h-[900px]"
			style={{
				backgroundImage: `url(/assets/background.svg)`,
				backgroundRepeat: 'repeat',
			}}
		>
			<div className="video-background h-[375px] overflow-hidden sm:h-[500px] md:h-[675px] lg:h-[900px] xl:h-[900px]">
				<div className="video-foreground">
					<iframe
						title="background-video"
						src={`https://player.vimeo.com/video/${videoId}?background=1`}
						width="640"
						height="360"
						allow="autoplay; fullscreen"
						allowFullScreen
					></iframe>
					{/* <Vimeo
						background={true}
						loop={true}
						onEnd={() => setTime(0)}
						autoplay={true}
						ref={ref}
						responsive
						video={videoId}
					/> */}
				</div>
				{/* <VimeoBackgroundPlayer id={videoId} /> */}
			</div>
			<div className="relative z-30 flex h-full w-full flex-col space-y-[60px] bg-gradient-to-r from-green-100/60 to-green-100/50 sm:space-y-[120px] md:space-y-[220px]">
				<Navigation />
				<header className="hero-header container flex h-[170px] w-full flex-col space-y-4 lg:h-[270px]">
					<h2 className="font-sans-serif text-2xl leading-[38px] tracking-wide text-white md:text-5xl md:leading-[58px]">
						{subtitle}
					</h2>
					<SwitchTransition mode="out-in">
						<CSSTransition
							key={time}
							nodeRef={textRef}
							addEndListener={(done: any) => {
								if (textRef.current)
									textRef.current.addEventListener('transitionend', done, false)
							}}
							classNames="fade"
						>
							<h3
								ref={textRef}
								className={`font-serif text-[50px] font-medium leading-[46px] text-white md:text-[96px] md:leading-[90px]`}
							>
								{rotatingText &&
									rotatingText[time % rotatingText?.length || 0]?.text}
							</h3>
						</CSSTransition>
					</SwitchTransition>
				</header>
			</div>
		</div>
	)
}
